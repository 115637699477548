import * as Tabs from '@radix-ui/react-tabs'
import { useAuth } from '@upper/auth'
import {
  FormikCheckbox,
  FormikSubmitButton,
  FormikTextField,
} from '@upper/formik'
import {
  ApplyToJobDocument,
  CalendlyEventDocument,
  ConfirmInterviewDocument,
  CreateMeetingDocument,
  CreateReferralDocument,
  EngagementDetailsFragment,
  EngagementStatus,
  JobDetailsDocument,
  JobDetailsFragment,
  MeetingStatus,
  MeetingType,
  UpdateEngagementStatusDocument,
} from '@upper/graphql/freelancer'
import { AlertIcon, CheckIcon, DotsHorizontalIcon } from '@upper/icons'
import {
  Button,
  SkillTag,
  SlideOver,
  SlideOverContent,
  SlideOverTitle,
  SliderOverActions,
  Spinner,
  classNames,
} from '@upper/ui'
import { formatTime } from '@upper/utils'
import compareDesc from 'date-fns/compareDesc'
import formatDate from 'date-fns/format'
import parseISODate from 'date-fns/parseISO'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import toast, { Toaster } from 'react-hot-toast'
import { useClient, useMutation, useQuery } from 'urql'
import { capitalize, words } from 'voca'
import * as Yup from 'yup'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogControls,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../components/alert-dialog'
import TextLinkButton from '../components/text-link-button'
import { useTalentStatus } from '../components/with-talent-status'

const INVITED_ENGAGEMENT_STATUSES = [EngagementStatus.Invited]

const APPLICATION_PROCESS_ENGAGEMENT_STATUSES = [
  EngagementStatus.Applied,
  EngagementStatus.InterviewUpperPersonal,
  EngagementStatus.Challenge,
  EngagementStatus.InterviewUpperTechnical,
  EngagementStatus.UpperOnboarding,
  EngagementStatus.Matching,
  EngagementStatus.Prepare,
  EngagementStatus.CreateProfile,
  EngagementStatus.ReadyToPropose,
  EngagementStatus.ProposedFellow,
  EngagementStatus.InterviewFellow,
  EngagementStatus.ProposedClient,
  EngagementStatus.ClientAssignment,
  EngagementStatus.InterviewClient,
  EngagementStatus.Onboarding,
  EngagementStatus.ExpertReview,
  EngagementStatus.Hired,
  EngagementStatus.Ended,
]

type JobDetailsProps = {
  jobId: string | null
  isTalentActive: boolean
  onClose: () => void
}

function JobDetailsWrapper({
  jobId,
  isTalentActive,
  onClose,
}: JobDetailsProps) {
  return (
    <SlideOver
      open={Boolean(jobId)}
      onOpenChange={(open) => {
        if (!open) {
          onClose()
        }
      }}
    >
      <SlideOverContent className="z-20 sm:w-[660px]">
        {jobId && (
          <JobDetails
            isTalentActive={isTalentActive}
            jobId={jobId}
            onClose={onClose}
          />
        )}
      </SlideOverContent>
    </SlideOver>
  )
}

function JobDetails({
  jobId,
  isTalentActive,
  onClose,
}: {
  jobId: string
  isTalentActive: boolean
  onClose: () => void
}) {
  const router = useRouter()
  const [showRefer, setShowRefer] = React.useState(false)
  const jobDetailsContext = React.useMemo(
    () => ({ additionalTypenames: ['Engagement'] }),
    []
  )
  const [jobDetailsResult] = useQuery({
    query: JobDetailsDocument,
    context: jobDetailsContext,
    variables: {
      id: jobId,
    },
  })

  const [updateEngagementStatusResult, updateEngagementStatus] = useMutation(
    UpdateEngagementStatusDocument
  )

  const job = jobDetailsResult.data?.job

  if (jobDetailsResult.fetching) {
    return (
      <div className="flex h-80 items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (jobDetailsResult.error) {
    return <div>Error: {jobDetailsResult.error.message}</div>
  }

  const engagement = jobDetailsResult.data?.job?.engagements?.[0]

  if (
    engagement &&
    engagement.latestRevision?.status &&
    APPLICATION_PROCESS_ENGAGEMENT_STATUSES.includes(
      engagement.latestRevision?.status
    )
  ) {
    return (
      <Tabs.Root
        defaultValue="application-process"
        className="flex h-full flex-col"
      >
        <div className="bg-blue px-5 pt-5 text-white sm:px-8 sm:pt-8">
          <SlideOverTitle asChild>
            <h3 className="pr-3 text-xl font-semibold leading-5 sm:pr-0 sm:text-2xl sm:leading-6">
              {job?.name}
            </h3>
          </SlideOverTitle>
          <p className="mt-2 leading-tight">{job?.publicPageHeadline}</p>
          <Tabs.List className="mt-8 flex space-x-3">
            <Tabs.Trigger value="application-process" asChild>
              <TabButton>Application Process</TabButton>
            </Tabs.Trigger>
            <Tabs.Trigger value="job-description" asChild>
              <TabButton>Job Description</TabButton>
            </Tabs.Trigger>
          </Tabs.List>
        </div>

        <Tabs.Content
          value="application-process"
          className="min-h-0 flex-1 overflow-y-scroll outline-none"
        >
          <ApplicationProcess
            job={job!}
            engagement={engagement}
            onClose={onClose}
          />
        </Tabs.Content>
        <Tabs.Content
          value="job-description"
          className="min-h-0 flex-1 overflow-y-scroll outline-none"
        >
          <JobDescription job={job!} />
        </Tabs.Content>
      </Tabs.Root>
    )
  }

  const canApplyToJob =
    !engagement ||
    (engagement &&
      engagement.latestRevision?.status &&
      INVITED_ENGAGEMENT_STATUSES.includes(engagement.latestRevision?.status))

  return (
    <>
      <Toaster />
      <div className="bg-blue p-5 text-white sm:p-8">
        <div className="flex items-end justify-between">
          <SlideOverTitle asChild>
            <h3 className="pr-3 text-xl font-semibold leading-5 sm:pr-0 sm:text-2xl sm:leading-6">
              {job?.name}
            </h3>
          </SlideOverTitle>
          <a
            href={`${process.env.NEXT_PUBLIC_PUBLIC_APP_URL}/job/${job?.id}`}
            target="_blank"
            className="text-cyan hidden whitespace-nowrap text-sm underline hover:no-underline sm:inline"
            rel="noreferrer"
          >
            view job page
          </a>
        </div>
        <p className="mt-2 leading-tight">{job?.publicPageHeadline}</p>
        <div className="mt-2 sm:hidden">
          <a
            href={`${process.env.NEXT_PUBLIC_PUBLIC_APP_URL}/job/${job?.id}`}
            target="_blank"
            className="text-cyan text-sm underline hover:no-underline"
            rel="noreferrer"
          >
            view job page
          </a>
        </div>
      </div>

      <div className="min-h-0 flex-1 overflow-y-scroll">
        <JobDescription job={job!} />
      </div>

      {canApplyToJob && (
        <>
          {job?.startDate && (
            <div className="bg-yellow flex items-start px-5 py-2 sm:px-8">
              <div className="mr-3 flex-shrink-0">
                <AlertIcon className="h-4 w-4" />
              </div>
              <div>
                <h3 className="text-sm font-bold leading-tight">
                  {job?.startDate ? (
                    // has date
                    <>
                      Please note that{' '}
                      {formatDate(parseISODate(job?.startDate), 'PP')} is the
                      start date for this position.
                    </>
                  ) : (
                    // asap
                    <>The position start date is as soon as possible.</>
                  )}
                </h3>
                <p className="text-sm leading-tight">
                  Please only submit the application if your availability suits
                  the timeline.
                </p>
              </div>
            </div>
          )}

          <SliderOverActions>
            <Button
              onClick={() => {
                router.replace(
                  {
                    query: {
                      ...router.query,
                      apply: true,
                    },
                  },
                  undefined,
                  {
                    scroll: false,
                    shallow: true,
                  }
                )
              }}
            >
              Apply now
            </Button>
            {engagement &&
              engagement.latestRevision?.status &&
              INVITED_ENGAGEMENT_STATUSES.includes(
                engagement.latestRevision?.status
              ) && (
                <Button
                  variant="outline"
                  isLoading={updateEngagementStatusResult.fetching}
                  onClick={async () => {
                    const result = await updateEngagementStatus({
                      id: engagement.id!,
                      data: {
                        status: EngagementStatus.RejectedFreelancer,
                      },
                    })
                    if (result.error) {
                      toast.error(result.error.message)
                    } else {
                      onClose()
                    }
                  }}
                >
                  Not interested
                </Button>
              )}
            <Button
              variant="outline"
              onClick={() => {
                setShowRefer(true)
              }}
            >
              Refer a friend
            </Button>
          </SliderOverActions>

          {router.query.apply && (
            <ConfirmJobApplication
              job={job!}
              isTalentActive={isTalentActive}
              onApplicationSent={() => {
                router.replace(
                  {
                    query: {
                      ...router.query,
                      apply: null,
                    },
                  },
                  undefined,
                  {
                    scroll: false,
                    shallow: true,
                  }
                )
              }}
              onCancel={() => {
                router.replace(
                  {
                    query: {
                      ...router.query,
                      apply: null,
                    },
                  },
                  undefined,
                  {
                    scroll: false,
                    shallow: true,
                  }
                )
              }}
            />
          )}

          {showRefer && (
            <ReferFriend
              jobId={job?.id ?? ''}
              onCancel={() => {
                setShowRefer(false)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

function JobDescription({ job }: { job: JobDetailsFragment }) {
  return (
    <>
      <div className="p-5 sm:p-8">
        <dl className="space-y-4 sm:space-y-5">
          <DescriptionItem label="Engagement">
            {capitalize(words(job.commitment as string).join(' '), true)}
          </DescriptionItem>

          <DescriptionItem label="Start date">
            {job.startDate
              ? formatDate(parseISODate(job.startDate), 'PP')
              : 'As soon as possible'}{' '}
            {job.length && `(${job.length})`}
          </DescriptionItem>

          <DescriptionItem label="Language">
            {job.languages &&
              job.languages.map((language) => language.name).join(', ')}
          </DescriptionItem>

          <DescriptionItem label="Location">{job.location}</DescriptionItem>

          <DescriptionItem label="Must have skills">
            <ul className="-mt-1 -ml-1 flex flex-wrap">
              {job.requiredSkills?.map((skill) => (
                <li key={skill.id} className="mt-1 ml-1">
                  <SkillTag>{skill.name}</SkillTag>
                </li>
              ))}
            </ul>
          </DescriptionItem>

          {job.niceToHaveSkills && job.niceToHaveSkills.length > 0 && (
            <DescriptionItem label="Good to have skills">
              <ul className="-mt-1 -ml-1 flex flex-wrap">
                {job.niceToHaveSkills?.map((skill: any) => (
                  <li key={skill.id} className="mt-1 ml-1">
                    <SkillTag type="secondary">{skill.name}</SkillTag>
                  </li>
                ))}
              </ul>
            </DescriptionItem>
          )}

          {(!job.showRatePublicly || job.publicPageRateDetails) && (
            <DescriptionItem label="Rate">
              {!job.showRatePublicly
                ? 'We use your own rate'
                : job.publicPageRateDetails}
            </DescriptionItem>
          )}

          {job.mindset && (
            <DescriptionItem label="Mindset">{job.mindset}</DescriptionItem>
          )}

          {job.teamSize && (
            <DescriptionItem label="Team">{job.teamSize}</DescriptionItem>
          )}

          <DescriptionItem label="Project description">
            <div
              className="prose-sm prose"
              dangerouslySetInnerHTML={{ __html: job.publicPageDescription! }}
            />
          </DescriptionItem>

          <DescriptionItem label="About the client">
            <div
              className="prose-sm prose"
              dangerouslySetInnerHTML={{
                __html: job.publicPageAboutTheClient!,
              }}
            />
          </DescriptionItem>
        </dl>
      </div>

      {job.jobSourcers && job.jobSourcers?.length > 0 && (
        <div className="sm:p-2">
          <div className="bg-gray-lightest grid gap-4 p-5 sm:grid-cols-3 sm:rounded-md sm:p-6">
            <h3 className="leading-tight opacity-50">Meet the Sourcer:</h3>
            <div className="flex sm:col-span-2">
              <div className="flex-1">
                <h4>
                  {job.jobSourcers[0]?.sourcer?.firstName}{' '}
                  {job.jobSourcers[0]?.sourcer?.lastName}
                </h4>
                <p
                  className="prose-sm prose text-gray-dark mt-2 leading-tight"
                  dangerouslySetInnerHTML={{
                    __html: job.jobSourcers[0]?.sourcer?.description ?? '',
                  }}
                />
              </div>
              {job.jobSourcers[0]?.sourcer?.profilePicUrl && (
                <img
                  className="ml-5 h-20 w-20 rounded-md object-cover sm:h-24 sm:w-24"
                  src={job.jobSourcers[0]?.sourcer?.profilePicUrl}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function DescriptionItem({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
      <dd className="opacity-50">{label}:</dd>
      <dt className="sm:col-span-2">{children}</dt>
    </div>
  )
}

const TabButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'>
>((props, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className="text-cyan state-active:text-blue state-active:bg-white rounded-t-md rounded-b-none px-3 py-3.5 text-sm font-semibold hover:bg-white/10 sm:px-5"
      {...props}
    />
  )
})

interface ConfirmFormValues {
  confirmDate: boolean
  confirmCommitment: boolean
  confirmReadDescription: boolean
  recommendsYou?: string
}

function ConfirmJobApplication({
  job,
  isTalentActive,
  onCancel,
  onApplicationSent,
}: {
  job: JobDetailsFragment
  isTalentActive: boolean
  onCancel: () => void
  onApplicationSent: (engagementId: string) => void
}) {
  const { talentId } = useTalentStatus()
  const [applyToJobResult, applyToJob] = useMutation(ApplyToJobDocument)
  const [, updateEngagementStatus] = useMutation(UpdateEngagementStatusDocument)

  const initialValues: ConfirmFormValues = {
    confirmDate: false,
    confirmCommitment: false,
    confirmReadDescription: false,
    recommendsYou: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        confirmDate: Yup.boolean().isTrue('Field must be checked'),
        confirmCommitment: Yup.boolean().isTrue('Field must be checked'),
        confirmReadDescription: Yup.boolean().isTrue('Field must be checked'),
        recommendsYou: Yup.string(),
      })}
      onSubmit={async (values: ConfirmFormValues) => {
        if (job.engagements!.length > 0) {
          const result = await updateEngagementStatus({
            id: job.engagements?.[0]?.id ?? '',
            data: {
              status: EngagementStatus.Applied,
            },
          })
          if (result.error) {
            toast.error(result.error.message)
          } else {
            onApplicationSent(result.data?.updateEngagementStatus.id ?? '')
          }
        } else {
          const result = await applyToJob({
            jobId: job.id!,
          })

          if (result.error) {
            if (
              result.error?.graphQLErrors?.[0].extensions?.errorCode ===
              'jobNotAcceptingEngagements'
            )
              toast.error('Sorry, this job is not available.')
            else toast.error(result.error.message)
          } else {
            onApplicationSent(result.data?.applyToJob.id ?? '')
          }
        }
      }}
    >
      {({ values }) => (
        <Form className="absolute inset-0 flex flex-col bg-white">
          <div className="min-h-0 flex-1 overflow-y-scroll p-5 sm:p-8">
            <h3 className="text-blue pr-3 text-xl font-semibold leading-6 sm:pr-0 sm:text-2xl sm:leading-7">
              You are applying for the <br /> {job.name} position
            </h3>

            <div className="mt-5 space-y-2 sm:mt-8">
              <div className="bg-gray-lightest border-gray-light rounded-md border p-5">
                <FormikCheckbox name="confirmDate">
                  {job.startDate ? (
                    <>
                      I understand that{' '}
                      {formatDate(parseISODate(job.startDate), 'PPP')} is the
                      start date for this position, and this suits my
                      availability.
                    </>
                  ) : (
                    <>
                      I understand that the position start date is as soon as
                      possible, and this suits my availability.
                    </>
                  )}
                </FormikCheckbox>
              </div>
              <div className="bg-gray-lightest border-gray-light rounded-md border p-5">
                <FormikCheckbox name="confirmCommitment">
                  I’m available{' '}
                  {capitalize(words(job.commitment as string).join(' '), true)}
                </FormikCheckbox>
              </div>
              <div className="bg-gray-lightest border-gray-light rounded-md border p-5">
                <FormikCheckbox name="confirmReadDescription">
                  I’ve read & understood the job description
                </FormikCheckbox>
              </div>
            </div>
            {applyToJobResult.error?.graphQLErrors?.[0].extensions?.code ===
              '409' && (
              <div className="text-red mt-5">
                You already applied for this job
              </div>
            )}
          </div>
          <SliderOverActions>
            <FormikSubmitButton
              disabled={
                !values.confirmCommitment ||
                !values.confirmDate ||
                !values.confirmReadDescription
              }
            >
              Send application
            </FormikSubmitButton>
            <Button variant="outline" type="button" onClick={onCancel}>
              Cancel
            </Button>
          </SliderOverActions>
        </Form>
      )}
    </Formik>
  )
}

const APPLICATION_PROCESS_STEPS = {
  1: [
    EngagementStatus.Applied,
    EngagementStatus.InterviewUpperPersonal,
    EngagementStatus.Challenge,
    EngagementStatus.InterviewUpperTechnical,
    EngagementStatus.UpperOnboarding,
  ],
  2: [
    EngagementStatus.Matching,
    EngagementStatus.ExpertReview,
    EngagementStatus.Prepare,
    EngagementStatus.CreateProfile,
  ],
  3: [
    EngagementStatus.ReadyToPropose,
    EngagementStatus.ProposedFellow,
    EngagementStatus.ProposedClient,
  ],
  4: [
    EngagementStatus.InterviewFellow,
    EngagementStatus.InterviewClient,
    EngagementStatus.ClientAssignment,
  ],
  5: [
    EngagementStatus.Onboarding,
    EngagementStatus.Hired,
    EngagementStatus.Ended,
  ],
}

function ApplicationProcess({
  job,
  engagement,
  onClose,
}: {
  job: JobDetailsFragment
  engagement: EngagementDetailsFragment
  onClose: () => void
}) {
  const router = useRouter()
  const [, updateEngagementStatus] = useMutation(UpdateEngagementStatusDocument)
  const currentStep = Object.entries(APPLICATION_PROCESS_STEPS).find(
    ([step, statuses]) =>
      engagement.latestRevision?.status &&
      statuses.includes(engagement.latestRevision?.status)
  )

  if (!currentStep) {
    return <div className="p-5 sm:p-8">Application process complete</div>
  }

  const currentStepNumber = Number(currentStep[0])
  if (engagement.latestRevision?.status === EngagementStatus.Hired) {
    return (
      <div className="rounded-lg py-40 text-center">
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto mb-4"
        >
          <rect width="80" height="80" rx="40" fill="#00CA75" />
          <mask id="path-2-inside-1_4766_1057" fill="white">
            <path d="M29.3671 40.8451C28.6928 40.2363 27.6207 40.2562 26.9725 40.8897C26.3244 41.5232 26.3456 42.5303 27.02 43.1391L34.912 50.5561C35.5952 51.173 36.6843 51.1432 37.3282 50.49L54.0491 33.6718C54.6846 33.0271 54.6434 32.0206 53.9571 31.4236C53.2708 30.8266 52.1993 30.8653 51.5638 31.51L36.0152 47.139L29.3671 40.8451Z" />
          </mask>
          <path
            d="M29.3671 40.8451L32.1171 37.9404L32.0828 37.9079L32.0477 37.8762L29.3671 40.8451ZM27.02 43.1391L29.7593 40.2244L29.7302 40.197L29.7006 40.1702L27.02 43.1391ZM34.912 50.5561L32.1727 53.4709L32.2018 53.4982L32.2314 53.525L34.912 50.5561ZM37.3282 50.49L34.4916 47.6698L34.4855 47.6759L34.4795 47.682L37.3282 50.49ZM54.0491 33.6718L56.8857 36.492L56.8917 36.486L56.8977 36.4799L54.0491 33.6718ZM51.5638 31.51L54.3995 34.3311L54.406 34.3246L54.4125 34.318L51.5638 31.51ZM36.0152 47.139L33.2652 50.0437L36.0989 52.7263L38.8509 49.9601L36.0152 47.139ZM32.0477 37.8762C29.8119 35.8576 26.3414 35.9133 24.1767 38.0291L29.7684 43.7503C28.8999 44.5992 27.5736 44.615 26.6865 43.814L32.0477 37.8762ZM24.1767 38.0291C21.8711 40.2825 21.9601 43.9598 24.3394 46.1081L29.7006 40.1702C30.7312 41.1007 30.7777 42.7639 29.7684 43.7503L24.1767 38.0291ZM24.2807 46.0539L32.1727 53.4709L37.6513 47.6413L29.7593 40.2244L24.2807 46.0539ZM32.2314 53.525C34.4942 55.5681 38.021 55.4851 40.1769 53.298L34.4795 47.682C35.3477 46.8012 36.6962 46.7779 37.5926 47.5872L32.2314 53.525ZM40.1648 53.3102L56.8857 36.492L51.2124 30.8516L34.4916 47.6698L40.1648 53.3102ZM56.8977 36.4799C59.1668 34.178 58.9943 30.5037 56.5823 28.4056L51.3319 34.4416C50.2925 33.5374 50.2024 31.8763 51.2004 30.8638L56.8977 36.4799ZM56.5823 28.4056C54.3147 26.4331 50.8451 26.5411 48.7151 28.702L54.4125 34.318C53.5535 35.1895 52.227 35.2202 51.3319 34.4416L56.5823 28.4056ZM48.7281 28.6889L33.1795 44.3178L38.8509 49.9601L54.3995 34.3311L48.7281 28.6889ZM38.7652 44.2342L32.1171 37.9404L26.6171 43.7499L33.2652 50.0437L38.7652 44.2342Z"
            fill="white"
            mask="url(#path-2-inside-1_4766_1057)"
          />
        </svg>
        {`Congratulation, you're all set! 🙌`}
      </div>
    )
  }
  return (
    <>
      <div className="flow-root p-5 sm:p-8">
        <ul role="list" className="-mb-10">
          <ApplicationProcessStep
            number={1}
            currentStepNumber={currentStepNumber}
            title="Applied"
          >
            <p className="text-gray-dark text-sm">
              Thank you for submitting your application.
            </p>
            <p className="text-gray-dark text-sm">
              Our team will review your profile and get back to you shortly.
            </p>
            <p className="text-gray mt-5 flex items-center text-sm">
              <AlertIcon className="mr-1 h-4 w-4" />
              This process usually takes around 24-48h
            </p>
          </ApplicationProcessStep>
          <MatchingStep
            currentStepNumber={currentStepNumber}
            job={job}
            engagement={engagement}
          />

          <ApplicationProcessStep
            number={3}
            currentStepNumber={currentStepNumber}
            title="Proposed"
          >
            <p className="text-gray-dark text-sm">
              Your skills and experience are well matched with the project. We
              have submitted your profile to the client to review.
            </p>
            <p className="text-gray mt-5 flex items-center text-sm">
              <AlertIcon className="mr-1 h-4 w-4" />
              This process can take anywhere between 1-14 days, depending on how
              responsive our client is
            </p>
          </ApplicationProcessStep>
          <InterviewStep
            currentStepNumber={currentStepNumber}
            engagement={engagement}
          />
          <ApplicationProcessStep
            number={5}
            currentStepNumber={currentStepNumber}
            title="Onboarding"
            isLast
          >
            {engagement.projectAgreementLink &&
            engagement.projectAgreementLink !== 'false' ? (
              <>
                <p className="text-gray-dark text-sm">
                  Congratulations! <br />
                  You are all set and ready to go. You can refer back to the
                  Project Agreement anytime.
                </p>
                <div className="mt-4">
                  <a
                    href={engagement.projectAgreementLink}
                    target="_blank"
                    className="text-blue inline-flex items-center underline hover:no-underline"
                    rel="noreferrer"
                  >
                    <svg
                      width={11}
                      height={15}
                      viewBox="0 0 11 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M6.125 4.469V.75H.656C.273.75 0 1.05 0 1.406v12.688c0 .383.273.656.656.656h9.188a.648.648 0 00.656-.656V5.125H6.781a.632.632 0 01-.656-.656zm1.75 6.453a.332.332 0 01-.328.328H2.953a.316.316 0 01-.328-.328v-.219c0-.164.137-.328.328-.328h4.594c.164 0 .328.164.328.328v.219zm0-1.75a.332.332 0 01-.328.328H2.953a.316.316 0 01-.328-.328v-.219c0-.164.137-.328.328-.328h4.594c.164 0 .328.164.328.328v.219zm0-1.969v.219a.332.332 0 01-.328.328H2.953a.316.316 0 01-.328-.328v-.219c0-.164.137-.328.328-.328h4.594c.164 0 .328.164.328.328zM10.5 4.086c0-.164-.082-.328-.191-.465L7.629.941C7.492.832 7.329.75 7.164.75H7v3.5h3.5v-.164z"
                        fill="#0F44D7"
                      />
                      <path d="M0 14.367h10.08v.684H0v-.684z" fill="#0F44D7" />
                    </svg>
                    Project Agreement
                  </a>
                </div>
              </>
            ) : (
              <p className="text-gray-dark text-sm">
                We’re in the process of getting you set up to start the project.
              </p>
            )}
          </ApplicationProcessStep>
        </ul>
      </div>
      {engagement.latestRevision?.status &&
        ![
          EngagementStatus.Onboarding,
          EngagementStatus.Hired,
          EngagementStatus.Ended,
        ].includes(engagement.latestRevision?.status) && (
          <div className="bg-yellow mt-4 flex items-start px-5 py-2 sm:mx-8 sm:mt-0 sm:mb-8 sm:rounded-md sm:p-3">
            <div className="mr-3 flex-shrink-0">
              <AlertIcon className="h-4 w-4" />
            </div>
            <div>
              <p className="text-sm leading-tight">
                {job?.startDate ? (
                  // has date
                  <>
                    Don’t forget, for this job you have to be available starting{' '}
                    {formatDate(parseISODate(job.startDate), 'PPP')}
                  </>
                ) : (
                  // asap
                  <>
                    Don’t forget, for this job you have to be available right
                    away.
                  </>
                )}
              </p>
              <p className="text-sm font-bold leading-tight">
                If you think you can’t be available, please{' '}
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <button
                      type="button"
                      className="font-bold underline hover:no-underline"
                    >
                      cancel your application
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogTitle>
                      Are you sure you want to cancel your application?
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      You are trying to cancel your application to {job.name}{' '}
                      job
                    </AlertDialogDescription>
                    <AlertDialogControls>
                      <AlertDialogAction
                        onClick={async () => {
                          const result = await updateEngagementStatus({
                            id: engagement.id!,
                            data: {
                              status: EngagementStatus.RejectedFreelancer,
                            },
                          })
                          if (result.error) {
                            toast.error(result.error.message)
                          } else {
                            onClose()
                            router.push('/my-jobs')
                          }
                        }}
                      >
                        Yes, cancel my application
                      </AlertDialogAction>
                      <AlertDialogCancel>
                        No, keep application open
                      </AlertDialogCancel>
                    </AlertDialogControls>
                  </AlertDialogContent>
                </AlertDialog>
                .
              </p>
            </div>
          </div>
        )}
    </>
  )
}

function ApplicationProcessStep({
  number,
  currentStepNumber,
  title,
  children,
  isLast,
}: {
  number: number
  currentStepNumber: number
  title: string
  children?: React.ReactNode
  isLast?: boolean
}) {
  const isCompleted = currentStepNumber > number
  const isCurrent = currentStepNumber === number

  return (
    <div className="relative pb-10">
      {!isLast && (
        <span
          className="bg-gray-light absolute top-3 left-3 -ml-px h-full w-px"
          aria-hidden="true"
        />
      )}
      <div className="relative flex items-start space-x-3">
        <div
          className={classNames(
            'flex h-6 w-6 items-center justify-center rounded-full text-xs text-white ring-8 ring-white',
            isCompleted ? 'bg-blue' : isCurrent ? 'bg-gray-dark' : 'bg-gray'
          )}
        >
          {isCompleted ? (
            <CheckIcon className="h-3 w-3" />
          ) : isCurrent ? (
            <DotsHorizontalIcon className="h-3 w-3" />
          ) : (
            number
          )}
        </div>
        <div className="min-w-0 flex-1">
          <div className="flex pt-0.5">
            <h3
              className={classNames(
                'text-sm font-bold uppercase',
                isCompleted
                  ? 'text-blue'
                  : isCurrent
                  ? 'text-gray-dark'
                  : 'text-gray'
              )}
            >
              {title}
            </h3>
          </div>
          {isCurrent && children && <div className="mt-4">{children}</div>}
        </div>
      </div>
    </div>
  )
}

function MatchingStep({
  currentStepNumber,
  job,
  engagement,
}: {
  currentStepNumber: number
  job: JobDetailsFragment
  engagement: EngagementDetailsFragment
}) {
  const { user } = useAuth()
  const urqlClient = useClient()
  const [, createMeeting] = useMutation(CreateMeetingDocument)

  const meeting = engagement.meetings?.find(
    (meeting) => meeting.type === MeetingType.UpperMatching
  )

  const canPickDates =
    engagement.latestRevision?.status === EngagementStatus.Matching &&
    job.expert?.calendlyLink

  useCalendlyEventListener({
    onEventScheduled: async (event) => {
      const calendlyEventResult = await urqlClient
        .query(CalendlyEventDocument, {
          id: event.data.payload.event.uri.split('/')[4],
        })
        .toPromise()

      if (calendlyEventResult.error) {
        toast.error(calendlyEventResult.error.message)
      } else {
        createMeeting({
          data: {
            type: MeetingType.UpperMatching,
            jobId: job.id,
            engagementId: engagement.id,
            expertId: job.expert?.id,
            scheduledAt: calendlyEventResult.data?.calendlyEvent?.startTime,
          },
        })
      }
    },
  })

  return (
    <ApplicationProcessStep
      number={2}
      currentStepNumber={currentStepNumber}
      title="Matching"
    >
      {engagement.latestRevision?.status === EngagementStatus.ExpertReview ? (
        <p className="text-gray-dark text-sm">
          Our technical expert is reviewing your profile and making sure
          you&apos;re a good fit for the role. We&apos;ll get back to you ASAP.
        </p>
      ) : [EngagementStatus.Prepare].includes(
          engagement.latestRevision?.status ?? EngagementStatus.Prepare
        ) ? (
        <p className="text-gray-dark text-sm">
          We are ready to propose you to the client, we&apos;ll reach out with
          updates soon.
        </p>
      ) : [EngagementStatus.CreateProfile].includes(
          engagement.latestRevision?.status ?? EngagementStatus.Prepare
        ) ? (
        <p className="text-gray-dark text-sm">
          We are getting your profile ready and we&apos;ll send it to the client
          soon.
        </p>
      ) : (
        <p className="text-gray-dark text-sm">
          During the matching call, our expert will offer more details about the
          role and assess whether you are a good fit for the project.
        </p>
      )}
      {![EngagementStatus.Prepare, EngagementStatus.CreateProfile].includes(
        engagement.latestRevision?.status ?? EngagementStatus.Prepare
      ) && (
        <div className="mt-5">
          {meeting ? (
            <div className="bg-blue/10 text-gray-dark rounded-md px-5 py-4">
              Meeting set for{' '}
              {formatDate(
                parseISODate(meeting.scheduledAt),
                'dd.MM.yyyy | HH:mm'
              )}
            </div>
          ) : canPickDates ? (
            <div className="bg-gray-lightest -m-2 rounded-lg p-2">
              <InlineWidget
                url={job.expert?.calendlyLink ?? ''}
                styles={{ height: 500 }}
                prefill={{
                  name: `${user?.firstName} ${user?.lastName}`,
                  email: user?.email,
                }}
                pageSettings={{
                  hideEventTypeDetails: true,
                  hideGdprBanner: true,
                  hideLandingPageDetails: true,
                }}
              />
            </div>
          ) : engagement.latestRevision?.status ===
            EngagementStatus.ExpertReview ? (
            ''
          ) : (
            <p>
              We’re sorry but something went wrong while scheduling a matching
              call with our expert, please get in touch with us via the chat
              icon to the right.
            </p>
          )}
        </div>
      )}
      {[EngagementStatus.Prepare, EngagementStatus.CreateProfile].includes(
        engagement.latestRevision?.status ?? EngagementStatus.Prepare
      ) ? (
        <p className="text-gray mt-5 flex items-center text-sm">
          <AlertIcon className="mr-1 h-4 w-4" />
          This process normally takes maximum 24h
        </p>
      ) : (
        <p className="text-gray mt-5 flex items-center text-sm">
          <AlertIcon className="mr-1 h-4 w-4" />
          This process usually takes around 24-48h
        </p>
      )}
    </ApplicationProcessStep>
  )
}

function InterviewStep({
  currentStepNumber,
  engagement,
}: {
  currentStepNumber: number
  engagement: EngagementDetailsFragment
}) {
  const isClientInterview =
    engagement.latestRevision?.status === EngagementStatus.InterviewClient

  const filteredMeetings =
    engagement.meetings &&
    engagement.meetings.filter(
      (meeting) =>
        meeting.type ===
        (isClientInterview
          ? MeetingType.ClientInterview
          : MeetingType.FellowInterview)
    )

  const meeting =
    filteredMeetings && filteredMeetings.length > 0
      ? filteredMeetings.sort((a, b) =>
          compareDesc(parseISODate(a.createdAt), parseISODate(b.createdAt))
        )[0]
      : undefined
  const [selectedTime, setSelectedTime] = React.useState<string | null>(null)
  const [confirmInterviewResult, confirmInterview] = useMutation(
    ConfirmInterviewDocument
  )
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const groupedTimes = meeting?.proposedTimes
    ? meeting.proposedTimes.reduce<Record<string, string[]>>((acc, time) => {
        const day = formatDate(parseISODate(time), 'dd.MM.yyyy')
        if (acc[day]) {
          acc[day] = [...acc[day], time]
        } else {
          acc[day] = [time]
        }
        return acc
      }, {})
    : {}

  return (
    <ApplicationProcessStep
      number={4}
      currentStepNumber={currentStepNumber}
      title="Interview"
    >
      {meeting?.status === MeetingStatus.Proposed && (
        <>
          <p className="text-gray-dark text-sm">
            You have been invited for a final interview with the client.
          </p>
          <p className="text-blue mt-8 text-sm">
            What date/time suits you best? <strong>({timezone})</strong>
          </p>
          <div className="mt-5 space-y-3">
            {Object.entries(groupedTimes).map(([day, times]) => (
              <div key={day} className="flex">
                <div className="text-gray-dark mr-5 w-20 pt-1 text-sm">
                  {day}
                </div>
                <div className="flex flex-wrap gap-2">
                  {times.map((time) => (
                    <button
                      key={time}
                      className={classNames(
                        'border-gray rounded border px-2 py-1 text-sm',
                        time === selectedTime
                          ? 'bg-blue text-white'
                          : 'text-gray-dark bg-white'
                      )}
                      onClick={() => {
                        setSelectedTime(time)
                      }}
                    >
                      {formatTime(time)}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-5">
            <Button
              disabled={!selectedTime}
              isLoading={confirmInterviewResult.fetching}
              onClick={async () => {
                const result = await confirmInterview({
                  id: meeting.id!,
                  data: {
                    meetingType: isClientInterview
                      ? MeetingType.ClientInterview
                      : MeetingType.FellowInterview,
                    scheduledAt: selectedTime,
                    talentTimezone: timezone,
                  },
                })

                if (result.error) {
                  toast.error(result.error.message)
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </>
      )}
      {meeting?.status === MeetingStatus.Scheduled && meeting.scheduledAt && (
        <p>
          The call is confirmed for{' '}
          {formatDate(parseISODate(meeting.scheduledAt), 'dd.MM.yyyy HH:mm')}
        </p>
      )}
      {[
        EngagementStatus.InterviewClient,
        EngagementStatus.ClientAssignment,
      ].includes(
        engagement.latestRevision?.status ?? EngagementStatus.InterviewClient
      ) && (
        <p className="text-gray-dark text-sm">
          You are now in the interview process with the client. Let us know if
          you need anything and don’t forget to keep us in the loop.
        </p>
      )}
      {![
        EngagementStatus.InterviewClient,
        EngagementStatus.ClientAssignment,
      ].includes(
        engagement.latestRevision?.status ?? EngagementStatus.InterviewClient
      ) && (
        <p className="text-gray mt-5 flex items-center text-sm">
          <AlertIcon className="mr-1 h-4 w-4" />
          This process usually takes around 24-48h
        </p>
      )}
    </ApplicationProcessStep>
  )
}

interface FormValues {
  email: string
}

function ReferFriend({
  jobId,
  onCancel,
}: {
  jobId?: string
  onCancel: () => void
}) {
  const [createReferralResult, createReferral] = useMutation(
    CreateReferralDocument
  )

  const initialValues = {
    email: '',
  }

  const referralError = React.useMemo(() => {
    switch (
      createReferralResult.error?.graphQLErrors?.[0].extensions?.errorCode
    ) {
      case 'userExists':
        return (
          <>
            <h3 className="text-red text-center text-2xl font-semibold leading-tight">
              This e-mail
              <br /> is already registered.
            </h3>
            <p className="text-gray-dark mt-4 text-center font-medium">
              But we shared the job with your contact!
            </p>
          </>
        )
      case 'jobNotAcceptingEngagements':
        return (
          <>
            <h3 className="text-red text-center text-2xl font-semibold leading-tight">
              Sorry,
              <br />
              this job is not available.
            </h3>
          </>
        )
      case 'jobNotInStaffing':
        return (
          <>
            <h3 className="text-red text-center text-2xl font-semibold leading-tight">
              Sorry,
              <br />
              this job is not available.
            </h3>
          </>
        )
      case 'jobSourcingPaused':
        return (
          <>
            <h3 className="text-red text-center text-2xl font-semibold leading-tight">
              Sorry,
              <br />
              this job is not available.
            </h3>
          </>
        )
      default:
        return (
          <h3 className="text-red text-center text-2xl font-semibold leading-tight">
            {createReferralResult.error?.message}
          </h3>
        )
    }
  }, [
    createReferralResult.error?.graphQLErrors,
    createReferralResult.error?.message,
  ])

  return (
    <div className="absolute inset-0 flex flex-col bg-white">
      <div className="min-h-0 flex-1 overflow-y-scroll p-5 sm:p-8">
        {createReferralResult.error ? (
          <div className="pt-10">
            {referralError}
            <div className="mt-20 flex flex-col gap-4">
              <Button
                fullWidth
                onClick={() => {
                  onCancel()
                }}
              >
                Ok
              </Button>
              <Link href="/my-referrals" legacyBehavior>
                <TextLinkButton variant="gray" fullWidth>
                  My Referrals
                </TextLinkButton>
              </Link>
            </div>
          </div>
        ) : createReferralResult.data?.createReferral ? (
          <div>
            <h3 className="text-blue text-center text-2xl font-semibold leading-tight">
              Your invitation is <br /> successfully sent to
            </h3>
            <p className="text-gray-dark mt-4 text-center font-medium">
              {createReferralResult.data.createReferral.recipientEmail}
            </p>
            <div className="mt-20 flex flex-col gap-4">
              <Button
                fullWidth
                onClick={() => {
                  onCancel()
                }}
              >
                Ok
              </Button>
              <Link href="/my-referrals" legacyBehavior>
                <TextLinkButton variant="gray" fullWidth>
                  My Referrals
                </TextLinkButton>
              </Link>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            })}
            onSubmit={async (values: FormValues) => {
              await createReferral({
                data: {
                  jobId,
                  recipientEmail: values.email,
                },
              })
            }}
          >
            <Form>
              <h3 className="text-blue text-2xl font-semibold leading-tight">
                Know someone who could be a good fit
                <br /> for this job?
              </h3>
              <p className="text-gray-dark mt-4">
                Invite them to apply using the E-Mail field below{' '}
                <strong>
                  and earn ~1€/hour for each hour your friend works on UPPER
                  projects, forever.
                </strong>
              </p>
              <div className="mt-10 flex flex-wrap gap-2">
                <FormikTextField
                  name="email"
                  placeholder="E-mail of your friend"
                  className="w-[320px]"
                />
                <FormikSubmitButton>Send</FormikSubmitButton>
              </div>
            </Form>
          </Formik>
        )}
      </div>
      <SliderOverActions>
        <Button variant="outline" type="button" onClick={onCancel}>
          Cancel
        </Button>
      </SliderOverActions>
    </div>
  )
}

export default JobDetailsWrapper
